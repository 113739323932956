body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.app-container {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    line-height: 1.5;
}

h1 {
    font-weight: 400;
    font-size: 1.7rem;
    letter-spacing: 0.05rem;
    margin: 0;
}

h2 {
    font-weight: 400;
    font-size: 1.7rem;
    letter-spacing: 0.05rem;
    margin: 0;
}

h3 {
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        color: white;
    }
}

input:focus,
textarea:focus,
button:focus {
    outline: none;
}
